// furdexo-app-frontend/src/pages/customer/customer.scss
.customer-bg-color {
  background-color: #e6e6e6;
}

.text-word-break {
  width:180px;
  overflow-wrap: break-word;
}

.react-pdf__Page__annotations{
  display: none!important;
}