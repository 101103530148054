.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



:root {

  --base-text-font: 14px;
  --base-text-color: #0C0909;

  --small-heading-font: 16px;
  --small-heading-bold: bold;
  --small-heading-color: #0C0909;

  --medium-heading-font: 18px;
  --medium-heading-bold: bold;
  --medium-heading-color: #9A1275;

  --large-heading-font: 23px;
  --large-heading-bold: bold;
  --large-heading-color: #9A1275;

  --botton-bg-color: #9A1275;
  --botton-text-color: #ffffff;

  --border-color: #9A1275;
  --botton-text-color: #ffffff;

  --text-color: #9A1275;
}

.base-text{
  font-size: var(--base-text-font);
  color: var(--base-text-color);
}

.small-text{
  font-size: var(--small-heading-font);
  font-weight: var(--small-heading-bold);
  color: var(--small-heading-color);
}

.medium-text{
  font-size: var(--medium-heading-font);
  font-weight: var(--medium-heading-bold);
  color: var(--medium-heading-color);
}

.large-text{
  font-size: var(--large-heading-font);
  font-weight: var(--large-heading-bold);
  color: var(--large-heading-color);
}

.button-decorator{
  background-color: var(--botton-bg-color);
  color: var(--botton-text-color);
  font-weight: bold;
}
