/* index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.dashboard-container {
  @apply min-h-screen bg-gray-50;
}

.header {
  @apply bg-white border-b border-gray-200;
}

.header-content {
  @apply max-w-7xl mx-auto px-6 py-4;
}

.brand-title {
  @apply text-xl font-semibold text-gray-900;
}

.main-content {
  @apply max-w-7xl mx-auto px-6 py-8;
}

.dashboard-title {
  @apply text-2xl font-medium text-gray-900 mb-8;
}

.table-wrapper {
  @apply bg-white rounded-lg shadow overflow-hidden;
}

.dashboard-table {
  @apply min-w-full divide-y divide-gray-200;
}

.dashboard-table th {
  @apply px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50;
}

.table-row {
  @apply hover:bg-gray-50 transition-colors duration-150;
}

.customer-col {
  width: 20%;
}

.projects-col {
  width: 20%;
}

.status-col {
  width: 15%;
}

.update-col {
  width: 20%;
}

.date-col {
  width: 15%;
}

.actions-col {
  width: 10%;
}

.customer-cell {
  @apply px-6 py-4 whitespace-nowrap;
}

.row-number {
  @apply text-gray-400 mr-4 inline-block w-6 text-sm;
}

.customer-name {
  @apply text-sm text-gray-900;
}

.projects-cell {
  @apply px-6 py-4;
}

.projects-list {
  @apply space-y-1;
}

.project-item {
  @apply text-sm text-gray-500 px-2 py-1 rounded;
}

.active-project {
  @apply bg-gray-700 text-white;
}

.status-cell {
  @apply px-6 py-4 whitespace-nowrap text-sm text-gray-900;
}

.update-status-cell {
  @apply px-6 py-4 whitespace-nowrap;
}

.status-with-dropdown {
  @apply flex items-center space-x-2 text-sm text-gray-900;
}

.dropdown-icon {
  @apply w-4 h-4 text-gray-400;
}

.date-cell {
  @apply px-6 py-4 whitespace-nowrap;
}

.date-format {
  @apply text-sm text-gray-900;
}

.date-format sup {
  @apply text-xs;
}

.actions-cell {
  @apply px-6 py-4 whitespace-nowrap;
}

.view-all-btn {
  @apply inline-flex items-center px-3 py-2 border border-transparent text-sm 
         font-medium rounded-md text-white bg-gray-700 hover:bg-gray-800 
         transition-colors duration-150;
}

/* Loading States */
.loading-spinner {
  @apply flex items-center justify-center min-h-screen text-gray-600;
}

.error-message {
  @apply flex items-center justify-center min-h-screen text-red-600;
}

/* Status Dropdown */
.status-with-dropdown:hover .dropdown-icon {
  @apply transform rotate-180;
}

/* Row Zebra Striping */
.dashboard-table tbody tr:nth-child(even) {
  @apply bg-gray-50;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .main-content {
    @apply px-4;
  }
  
  .dashboard-table {
    @apply text-sm;
  }
}

@media (max-width: 768px) {
  .projects-list {
    @apply flex flex-col;
  }
  
  .project-item {
    @apply text-xs;
  }

  .projects-list {
    @apply space-y-1;
  }
  
  .project-item {
    @apply text-sm text-gray-500 px-2 py-1 rounded transition-colors duration-150;
  }
  
  .active-project {
    @apply bg-[#4B5563] text-white; /* Specific gray color for active projects */
  }
  
  .view-all-btn {
    @apply inline-flex items-center px-3 py-1.5 rounded-md text-sm font-medium
           text-white bg-[#4B5563] hover:bg-[#374151] transition-colors duration-150;
  }
  
  /* Specific Projects Styling */
  .projects-cell {
    @apply px-6 py-4;
  }
  
  .projects-list {
    @apply flex flex-col space-y-1;
  }
  
  .project-wrapper {
    @apply relative;
  }
  
  /* Non-selected projects */
  .project-item.inactive {
    @apply text-gray-500 hover:bg-gray-100;
  }
  
  /* Selected/current project */
  .project-item.active {
    @apply bg-[#4B5563] text-white;
  }
  
  /* Table row styling */
  .table-row {
    @apply border-b border-gray-100 hover:bg-gray-50 transition-colors duration-150;
  }
  
  /* Updated button states */
  .view-all-btn:focus {
    @apply outline-none ring-2 ring-offset-2 ring-gray-500;
  }
  
  .view-all-btn:disabled {
    @apply opacity-50 cursor-not-allowed;
  }
  
  /* Status with dropdown styling */
  .status-with-dropdown {
    @apply flex items-center justify-between cursor-pointer;
  }
  
  .dropdown-icon {
    @apply w-4 h-4 text-gray-400 transition-transform duration-150;
  }
  
  .status-with-dropdown:hover .dropdown-icon {
    @apply transform rotate-180;
  }

  /* Breadcrumb styles */
.breadcrumb-separator {
    @apply mx-2 text-gray-400;
  }
  
  /* Table specific styles for CustomerInfo */
  .customer-info-table {
    @apply min-w-full divide-y divide-gray-200;
  }
  
  .customer-info-header {
    @apply bg-gray-50 border-b border-gray-200;
  }
  
  .customer-info-row {
    @apply hover:bg-gray-50 transition-colors duration-150;
  }
  
  .customer-info-cell {
    @apply px-6 py-4 whitespace-nowrap text-sm;
  }
  
  /* Button styles specific to CustomerInfo */
  .action-button {
    @apply inline-flex items-center px-3 py-1.5 bg-[#4B5563] text-sm font-medium 
           text-white rounded-md hover:bg-[#374151] transition-colors duration-150;
  }
  
  /* Status dropdown styles */
  .status-dropdown {
    @apply flex items-center justify-between cursor-pointer;
  }
  
  .status-dropdown-icon {
    @apply h-4 w-4 text-gray-400 transition-transform duration-150;
  }
  
  .status-dropdown:hover .status-dropdown-icon {
    @apply transform rotate-180;
  }
  
  /* Header styles */
  .page-header {
    @apply bg-white border-b border-gray-200;
  }
  
  .header-content {
    @apply max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4;
  }
  
  .brand-title {
    @apply text-xl font-semibold text-gray-900;
  }
  
  /* Main content styles */
  .main-content {
    @apply max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8;
  }
  
  .page-title {
    @apply text-2xl font-medium text-gray-900 flex items-center;
  }


  
  /* Modal styles for OrderHistory and UploadFiles */
  .modal-overlay {
    @apply fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full 
           flex items-center justify-center;
  }
  
  .modal-container {
    @apply bg-white rounded-lg shadow-xl max-w-2xl w-full mx-4;
  }
  
  .modal-header {
    @apply px-6 py-4 border-b border-gray-200 flex justify-between items-center;
  }
  
  .modal-body {
    @apply px-6 py-4;
  }
  
  .modal-footer {
    @apply px-6 py-4 border-t border-gray-200;
  }
  
  /* Date format styles */
  .date-format {
    @apply text-sm text-gray-900;
  }
  
  .date-format sup {
    @apply text-xs;
  }

/* Order History specific styles */
.order-history-backdrop {
    @apply fixed inset-0 bg-black/25 flex items-start justify-center pt-16 z-50;
  }
  
  .order-history-container {
    @apply bg-white rounded-lg shadow-xl w-[420px] relative overflow-hidden;
  }
  
  .order-history-header {
    @apply flex justify-between items-center px-6 py-4;
  }
  
  .order-history-title {
    @apply text-lg font-medium text-gray-900;
  }
  
  .close-button {
    @apply text-gray-400 hover:text-gray-500 p-1 rounded-full
           transition-colors duration-150 hover:bg-gray-100;
  }
  
  .status-label {
    @apply text-sm text-gray-500 mb-4;
  }
  
  .timeline-container {
    @apply relative pl-6 space-y-6;
  }
  
  .timeline-line {
    @apply absolute left-[9px] top-[24px] bottom-6 w-[2px] bg-gray-200;
  }
  
  .timeline-item {
    @apply relative;
  }
  
  .timeline-dot {
    @apply absolute left-[-24px] top-[6px] w-[18px] h-[18px] 
           rounded-full bg-gray-600 border-[4px] border-gray-200;
  }
  
  .status-text {
    @apply text-sm font-medium text-gray-900 mb-1;
  }
  
  .date-time-container {
    @apply flex items-center space-x-2 text-sm text-gray-500 mb-2;
  }
  
  .view-button {
    @apply px-3 py-1 text-xs font-medium text-white bg-gray-600 
           rounded hover:bg-gray-700 transition-colors;
  }
  
  .meeting-details-button {
    @apply w-full py-3 px-6 text-sm text-white bg-gray-600 
           font-medium hover:bg-gray-700 transition-colors text-left;
  }
  
  /* Animation */
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .order-history-container {
    animation: slideIn 0.2s ease-out;
  }

  /* Cross Lines Styles */
.cross-lines {
  @apply absolute inset-0;
}

.line {
  @apply absolute top-1/2 left-1/2 bg-gray-200;
  width: 2px; /* Thicker lines */
  height: 141.4%; /* √2 × 100% to ensure lines reach corners */
  transform-origin: center;
}

.left-line {
  transform: translate(-50%, -50%) rotate(45deg);
}

.right-line {
  transform: translate(-50%, -50%) rotate(-45deg);
}

/* Add these to your existing styles */
.drawing-card {
  @apply relative bg-white rounded-md overflow-hidden;
}

.version-badge {
  @apply absolute top-2 right-2 z-10 bg-[#4B5563] text-white 
         px-2 py-0.5 text-xs font-medium rounded;
}

.drawing-preview {
  @apply relative aspect-square;
  height: 0;
  padding-bottom: 100%;
}

/* Drawing Name Bar */
.drawing-name {
  @apply bg-[#4B5563] text-white py-2 px-3 text-sm text-center 
         rounded-b font-medium;
}

/* Upload Button */
.upload-button {
  @apply block w-full text-center bg-[#4B5563] text-white text-sm 
         font-medium px-4 py-2 rounded cursor-pointer 
         hover:bg-[#374151] transition-colors duration-150;
}

/* Section Background */
.upload-section {
  @apply bg-gray-50 rounded-lg p-4;
}

/* Section Title */
.section-title {
  @apply text-sm font-medium mb-4;
}

/* Grid Layout */
.drawings-grid {
  @apply grid grid-cols-2 gap-4;
}

/* Modal Styles */
.modal-overlay {
  @apply fixed inset-0 bg-black/25 flex items-start justify-center pt-16 z-50;
}

.modal-content {
  @apply bg-white rounded-lg shadow-xl w-[800px];
}

.modal-header {
  @apply flex justify-between items-center px-6 py-4;
}

.modal-body {
  @apply px-6 pb-6;
}

/* Close Button */
.close-button {
  @apply text-gray-400 hover:text-gray-500;
}

/* Animation */
@keyframes modalFade {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal-fade {
  animation: modalFade 0.2s ease-out;
}
/* Drawing Card Styles */
.drawing-card {
  @apply relative bg-gray-50 rounded-lg overflow-hidden;
}

/* Drawing Preview Area */
.aspect-square {
  @apply relative w-full pt-[100%];
}

/* Cross Lines */
.cross-line {
  @apply absolute bg-gray-200;
  width: 2px;
  height: 141.4%;
  top: 50%;
  left: 50%;
  transform-origin: center;
}

.cross-line:first-child {
  transform: translate(-50%, -50%) rotate(45deg);
}

.cross-line:last-child {
  transform: translate(-50%, -50%) rotate(-45deg);
}

/* Version Badge */
.version-badge {
  @apply absolute right-2 top-2 bg-[#4B5563] text-white px-2 py-0.5 text-xs font-medium rounded z-10;
}

/* Drawing Name */
.drawing-name {
  @apply bg-[#4B5563] text-white py-2 text-sm font-medium text-center rounded-b;
}

/* Upload Button */
.upload-button {
  @apply block w-full text-center bg-[#4B5563] text-white text-sm font-medium px-4 py-2 rounded cursor-pointer hover:bg-[#374151] transition-colors;
}

/* Section Container */
.upload-section {
  @apply bg-gray-50 rounded-lg p-4;
}

/* Upload Modal */
.upload-modal {
  @apply fixed inset-0 bg-black/25 flex items-start justify-center pt-16 z-50;
}

.modal-content {
  @apply bg-white rounded-lg shadow-xl w-[800px];
}

/* Stats Card Styles */
.stats-card {
  @apply bg-white rounded-lg shadow p-6;
}

.stats-card .text-sm {
  @apply text-gray-500 mb-1;
}

.stats-card .text-2xl {
  @apply font-medium text-gray-900;
}

/* Update Status Styles */
.status-badge {
  @apply px-2 py-1 bg-gray-50 rounded text-sm text-gray-900;
}

.status-divider {
  @apply h-4 w-px bg-gray-300 mx-2;
}

/* Project List Styles - Updated */
.project-item-inactive {
  @apply text-sm text-gray-400;
}

.project-item-active {
  @apply text-sm px-3 py-1.5 bg-gray-600 text-white rounded;
}

/* Additional spacing adjustments */
.table-section {
  @apply mt-8;
}

.stats-section {
  @apply mb-8;
}

@layer components {
    /* Layout Components */
    .header {
      @apply bg-white border-b border-gray-200;
    }
  
    .header-content {
      @apply max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4;
    }
  
    .brand {
      @apply text-xl font-medium text-gray-900;
    }
  
    .dashboard-container {
      @apply max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6;
    }
  
    .dashboard-header {
      @apply mb-6;
    }
  
    .dashboard-title {
      @apply text-xl font-medium text-gray-900;
    }
  
    /* Stats Section */
    .stats-grid {
      @apply grid grid-cols-3 gap-6 mb-8;
    }
  
    .stats-box {
      @apply flex items-baseline space-x-2;
    }
  
    .stats-label {
      @apply text-sm text-gray-500;
    }
  
    .stats-value {
      @apply text-xl font-bold text-gray-900;
    }
  
    /* Table Components */
    .table-container {
      @apply bg-white shadow-sm rounded-lg overflow-hidden;
    }
  
    .table-header {
      @apply border-b border-gray-200;
    }
  
    .header-grid {
      @apply grid grid-cols-6;
    }
  
    .header-cell {
      @apply py-3 px-4 text-xs font-medium text-gray-500 uppercase;
    }
  
    .table-body {
      @apply divide-y divide-gray-100;
    }
  
    .table-row {
      @apply grid grid-cols-6 hover:bg-gray-50 transition-colors duration-150;
    }
  
    /* Table Cells */
    .customer-cell {
      @apply py-4 px-4 flex items-center;
    }
  
    .row-number {
      @apply text-gray-400 w-7 text-sm;
    }
  
    .customer-name {
      @apply text-sm text-gray-900;
    }
  
    .projects-cell {
      @apply py-4 px-4;
    }
  
    .projects-list {
      @apply flex flex-col space-y-1;
    }
  
    .project-item {
      @apply text-sm text-gray-400;
    }
  
    .project-item-active {
      @apply bg-[#4B5563] text-white text-sm py-1 px-3 rounded;
    }
  
    .status-cell {
      @apply py-4 px-4;
    }
  
    .status-text {
      @apply text-sm text-gray-900;
    }
  
    .update-status-cell {
      @apply py-4 px-4;
    }
  
    .status-with-dropdown {
      @apply flex items-center justify-between;
    }
  
    .status-badge {
      @apply text-sm text-gray-900 bg-gray-50 py-1 px-2 rounded;
    }
  
    .dropdown-indicator {
      @apply flex items-center ml-2;
    }
  
    .divider {
      @apply w-px h-4 bg-gray-300 mx-2;
    }
  
    .dropdown-icon {
      @apply h-4 w-4 text-gray-400;
    }
  
    .date-cell {
      @apply py-4 px-4;
    }
  
    .date-text {
      @apply text-sm text-gray-500;
    }
  
    .actions-cell {
      @apply py-4 px-4;
    }
  
    .action-button {
      @apply inline-flex items-center px-3 py-1.5 bg-[#4B5563] text-sm text-white 
             rounded hover:bg-[#374151] transition-colors duration-150;
    }
  
    /* Loading & Error States */
    .loading-container {
      @apply min-h-screen bg-gray-50 flex items-center justify-center;
    }
  
    .loading-spinner {
      @apply text-gray-600;
    }
  
    .error-container {
      @apply min-h-screen bg-gray-50 flex items-center justify-center;
    }
  
    .error-message {
      @apply text-red-600;
    }
  }
  
  /* Responsive Adjustments */
  @media (max-width: 1024px) {
    .dashboard-container {
      @apply px-4;
    }
  }
  
  @media (max-width: 768px) {
    .stats-grid {
      @apply grid-cols-1 gap-4;
    }
    
    .table-row {
      @apply grid-cols-1;
    }
}

/* Layout */
.dashboard-layout {
    @apply min-h-screen bg-gray-50;
  }
  
  /* Header */
  .header {
    @apply bg-white border-b border-gray-200;
  }
  
  .header-content {
    @apply max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4;
  }
  
  .brand {
    @apply text-xl font-medium text-gray-900;
  }
  
  /* Main Content */
  .main-content {
    @apply max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8;
  }
  
  /* Page Header */
  .page-header {
    @apply flex items-center justify-between mb-8;
  }
  
  .breadcrumb {
    @apply flex items-center space-x-2;
  }
  
  .separator {
    @apply text-gray-400 mx-2;
  }
  
  .current-page {
    @apply text-gray-900;
  }
  
  /* Stats */
  .stats-container {
    @apply flex items-center space-x-8;
  }
  
  .stats-indicator {
    @apply flex items-center space-x-2;
  }
  
  .stats-label {
    @apply text-gray-500 text-sm;
  }
  
  .stats-value {
    @apply text-xl font-semibold text-gray-900;
  }
  
  /* Table */
  .table-container {
    @apply bg-white shadow-sm rounded-lg overflow-hidden;
  }
  
  .data-table {
    @apply min-w-full divide-y divide-gray-200;
  }
  
  .data-table th {
    @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50;
  }
  
  .data-table tr {
    @apply hover:bg-gray-50;
  }
  
  .data-table td {
    @apply px-6 py-4 whitespace-nowrap text-sm;
  }
  
  /* Table Cells */
  .number-cell {
    @apply text-gray-400;
  }
  
  .text-cell {
    @apply text-gray-900;
  }
  
  /* Status Dropdown */
  .status-dropdown {
    @apply flex items-center justify-between;
  }
  
  .status-badge {
    @apply text-sm text-gray-900 bg-gray-50 px-2 py-1 rounded;
  }
  
  .dropdown-indicator {
    @apply flex items-center;
  }
  
  .divider {
    @apply h-4 w-px bg-gray-300 mx-2;
  }
  
  .dropdown-icon {
    @apply h-4 w-4 text-gray-400;
  }
  
  /* Action Buttons */
  .actions-group {
    @apply flex space-x-2;
  }
  
  .action-button {
    @apply inline-flex items-center px-3 py-1.5 text-sm font-medium text-white rounded 
           transition-colors duration-150;
  }
  
  .history-button {
    @apply bg-[#4B5563] hover:bg-[#374151];
  }
  
  .details-button {
    @apply bg-[#4B5563] hover:bg-[#374151];
  }
  
  .upload-button {
    @apply bg-[#4B5563] hover:bg-[#374151];
  }
  
  /* Loading & Error States */
  .loading-spinner {
    @apply flex items-center justify-center min-h-screen text-gray-600;
  }
  
  .error-message {
    @apply flex items-center justify-center min-h-screen text-red-600;
  }


  /* Custom button variants */
.btn-light {
    @apply bg-[#4B5563] hover:bg-[#374151] text-white;
  }
  
  .btn-dark {
    @apply bg-[#374151] hover:bg-[#4B5563] text-white;
  }
  
  /* Loading and Error states */
  .loading-state {
    @apply flex items-center justify-center min-h-screen bg-[#F9FAFB] text-gray-600;
  }
  
  .error-state {
    @apply flex items-center justify-center min-h-screen bg-[#F9FAFB] text-red-600;
  }
  
  /* Table cell with update status */
  .update-status-cell {
    @apply flex items-center justify-between bg-gray-50 px-3 py-1 rounded;
  }
  
  .update-status-divider {
    @apply h-4 w-px bg-gray-300 mx-2;
  }
  
  /* Action buttons container */
  .action-buttons-container {
    @apply flex flex-col space-y-2;
  }
  
  .action-buttons-row {
    @apply flex space-x-2;
  }
  
  /* Icon styling in buttons */
  .button-icon {
    @apply w-4 h-4 mr-2;
  }
  
  /* Stats indicator styling */
  .stats-indicator {
    @apply flex items-center space-x-2;
  }
  
  .stats-label {
    @apply text-gray-500 text-sm;
  }
  
  .stats-value {
    @apply text-2xl font-medium text-gray-900;
  }
  
  /* Header styling */
  .page-header {
    @apply bg-white border-b border-gray-200;
  }
  
  .header-content {
    @apply max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4;
  }
  
  /* Breadcrumb styling */
  .breadcrumb {
    @apply flex items-center;
  }
  
  .breadcrumb-separator {
    @apply mx-2 text-gray-400;
  }
  
  /* Table styling */
  .table-container {
    @apply bg-white shadow-sm rounded-lg overflow-hidden;
  }
  
  .table-header {
    @apply bg-gray-50;
  }
  
  .table-row-hover {
    @apply hover:bg-gray-50 transition-colors duration-150;
  }

  /* OrderDetails specific styles */
.quantity-control {
    @apply flex items-center space-x-2;
  }
  
  .quantity-button {
    @apply h-3 w-3 text-gray-400 hover:text-gray-600 cursor-pointer;
  }
  
  .detail-dropdown {
    @apply flex items-center justify-between cursor-pointer;
  }
  
  .room-header {
    @apply bg-gray-50 border-b border-gray-200;
  }
  
  .room-estimate {
    @apply text-sm text-gray-500;
  }
  
  /* Stats Section */
  .stats-section {
    @apply border-b border-gray-200 bg-white;
  }
  
  .stats-container {
    @apply flex justify-between items-center;
  }
  
  .stats-item {
    @apply flex items-center space-x-2;
  }
  
  .stats-label {
    @apply text-sm text-gray-500;
  }
  
  .stats-value {
    @apply text-2xl font-medium;
  }
  
  /* Table Styles */
  .table-container {
    @apply bg-white shadow-sm rounded-lg overflow-hidden;
  }
  
  .table-header {
    @apply bg-gray-50 border-b border-gray-200;
  }
  
  .table-row {
    @apply border-t border-gray-200 hover:bg-gray-50;
  }
  
  .cell-with-dropdown {
    @apply flex items-center justify-between;
  }
  
  .input-quantity {
    @apply w-12 text-sm text-gray-900 bg-transparent;
  }
}