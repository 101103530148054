/* Add this CSS to style the Processing component and spinner */

/* Style for the processing overlay */
.processing-overlay {
  position: fixed;   /* Fix the overlay to the entire screen */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);  /* Semi-transparent dark background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;  /* Ensure this overlay is on top of other content */
  flex-direction: column;
  color: white;
  font-size: 24px;
}

/* Spinner animation */
.spinner {
  margin-bottom: 20px;
  border: 4px solid rgba(255, 255, 255, 0.3);  /* Light color spinner */
  border-top: 4px solid #3498db;  /* Blue color */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

/* Spinner animation keyframes */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
